import { Button, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilState } from 'recoil';
import { forgotPasswordModalOpenedState } from './atoms';
import { getAuthDeferred } from '@/utils/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import useUser from '@/hooks/useUser';

function ForgotPasswordModal() {
  const { user } = useUser();

  const [isModalOpened, setIsModalOpened] = useRecoilState(
    forgotPasswordModalOpenedState,
  );

  const [isSending, setIsSending] = useState(false);

  const form = useForm({
    initialValues: {
      email: user?.email || '',
    },
  });

  const handleFormSubmit = () =>
    form.onSubmit(() => {
      setIsSending(true);
      sendPasswordResetEmail(getAuthDeferred(), form.values.email)
        .then(() => {
          toast.success('Password reset email sent');
        })
        .catch((error) => {
          toast.error("Couldn't send password reset email");
        })
        .finally(() => {
          setIsSending(false);
          setIsModalOpened(false);
        });
    });

  return (
    <Modal
      opened={isModalOpened}
      onClose={() => {
        setIsModalOpened(false);
      }}
      title="Reset password"
      centered>
      <form onSubmit={handleFormSubmit()}>
        <TextInput
          readOnly={!!user}
          label="Email"
          placeholder="Enter your email"
          required
          type="email"
          disabled={isSending || !!user}
          value={form.values.email}
          onChange={(event) =>
            form.setFieldValue('email', event.currentTarget.value)
          }
          error={form.errors.email && 'Please enter a valid email'}
        />
        <Button
          loading={isSending}
          className="mt-2"
          type="submit"
          variant="light"
          color="ocean-blue">
          {'Send reset link'}
        </Button>
      </form>
    </Modal>
  );
}

export default ForgotPasswordModal;
