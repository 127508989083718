import { atom } from 'recoil';

export const forgotPasswordModalOpenedState = atom({
  key: 'forgotPasswordModalOpenedState',
  default: false,
});

export const showSignInState = atom({
  key: 'showSignIn',
  default: true,
});
